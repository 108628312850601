.document-list-item-container {
  @apply border border-lightGrey rounded shadow;

  .document-list-item-image-container {
    @apply w-full cursor-pointer;

    .document-list-item-image {
      @apply object-cover w-full h-56 transition duration-150 ease-in-out transform;

      @screen md {
        @apply h-64;
      }

      @screen lg {
        @apply h-72;
      }

      @screen xxxl {
        @apply h-84;
      }
    }

    .document-list-item-image:hover {
      @apply scale-105 shadow-lg;
    }
  }

  .document-list-item-caption-container {
    @apply flex flex-row items-center my-4 mx-2;

    @screen md {
      @apply my-6 mx-2;
    }

    .document-list-item-caption {
      @apply flex-1;

      .document-list-item-caption-text {
        @apply text-grey font-body text-base font-light;

        @screen lg {
          @apply text-lg;
        }
      }

      .document-list-item-caption-published-date {
        @apply text-grey font-body text-xs font-light text-opacity-50;
      }
    }

    .document-list-item-type-icon {
      @apply text-grey font-body text-base font-light text-opacity-50;

      @screen lg {
        @apply text-lg;
      }
    }
  }
}
