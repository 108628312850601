.document-video {

  iframe {
    @apply w-full h-72 mb-12;

    @screen md {
      @apply h-84;
    }

    @screen lg {
      @apply h-96;
    }

    @screen xxl {
      @apply h-108;
    }
  }
}