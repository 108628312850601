.error-container {
  @apply pt-20 w-full flex flex-col items-center justify-center;
}

.error {
  @apply bg-green w-full text-white h-96 text-center flex flex-col items-center justify-center;
}

.error-title {
  @apply text-lg;

  @screen md {
    @apply text-xl;
  }

  @screen lg {
    @apply text-2xl;
  }
}

.error-body {
  @apply mt-8;
}