.documents-container {
  @apply w-full pb-20;

  .documents-header {
    @apply w-full flex flex-wrap items-center justify-between mt-0 px-4 pt-8;

    .documents-category-title {
      @apply font-bold text-xl text-grey;
    }

    .documents-toolbar {
      @apply flex flex-1 flex-row justify-end;

      .documents-toolbar-button {
        @apply text-grey font-body text-lg font-thin mx-2;

        @screen lg {
          @apply text-xl;
        }
      }
    }
  }

  .documents-search-container {
    @apply relative w-full shadow-xl;

    .documents-search {
      @apply p-4 text-black border-t border-green;

      .documents-search-field {
        @apply w-full transition p-2 appearance-none text-lg;
      }

      .documents-search-field:focus {
        @apply outline-none border-none;
      }

      .documents-search-icon {
        @apply text-grey font-body text-lg font-thin mx-4 absolute right-0 top-0 h-full text-center;

        @screen lg {
          @apply text-xl;
        }
      }
    }
  }

  .documents-load-more-container {
    @apply w-full pt-8 flex items-center justify-center;

    .documents-load-more-button {
      @apply bg-green text-white py-2 px-4 rounded text-lg;

      @screen lg {
        @apply text-xl;
      }
    }
  }
}