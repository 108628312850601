.document-content-container {
  @apply relative pt-10 mb-20 text-grey px-4 mx-auto text-sm max-w-screen-md font-body font-light;

  @screen sm {
    @apply text-base;
  }

  @screen lg {
    @apply text-lg;
  }

  p {
    @apply mb-4;

    @screen sm {
      @apply mb-6;
    }

    @screen lg {
      @apply mb-8;
    }
  }

  a {
    @apply text-link underline;
  }
}

::-ms-clear {
  display: none;
}