.header-container {
  @apply bg-white fixed w-full z-10 top-0 shadow;

  .header {
    @apply w-full flex flex-row justify-between items-center px-4 py-2 min-h-4rem relative;

    @screen lg {
      @apply min-h-5rem;
    }

    .header-logo {
    }

    .header-categories {
      @apply hidden items-center justify-center;

      @screen lg {
        @apply flex flex-row flex-wrap flex-1;
      }
    }

    .header-menu-button {
      @apply absolute px-2 py-1 border border-grey rounded right-0 mr-4 text-grey;
    }

    .header-menu-icon {
      @apply text-grey font-body text-lg font-thin mx-2;

      @screen lg {
        @apply text-lg;
      }
    }
  }

  .header-menu-container {
    @apply relative w-full shadow-xl;

    .header-menu {
      @apply py-4 text-black border-t border-green flex flex-row flex-wrap justify-center items-center;
    }
  }
}

.header-category {
  @apply cursor-pointer uppercase text-sm mx-2 font-light;

  @screen lg {
    @apply text-base mx-3;
  }
}

.header-category:hover {
  @apply underline;
}

.header-category-active {
  @apply text-green;
}

.header-category-non-active {
  @apply text-grey;
}
