.logo {
  @apply bg-blue w-auto py-1 px-3;

  @screen lg {
    @apply py-2 px-5;
  }

  svg {
    @apply mx-auto;
    width: 45px;
    height: 45px;

    @screen lg {
      width: 55px;
      height: 55px;
    }
  }
}
