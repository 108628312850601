.footer-container {
  @apply w-full bg-blue;

  .footer {
    @apply pt-8 max-w-screen-lg;
  }

  .info {
    @apply flex flex-col w-full p-8;

    @screen md {
      @apply flex-row;
    }
  }

  .copyright {
    @apply pb-8 pt-4 border-t border-grey flex flex-col items-center text-lightGrey text-base font-thin text-center;

    .copyright-title {
      @apply mt-4;
    }

    a:hover {
      @apply underline;
    }
  }

  .terms-conditions {
    @apply text-lightGrey text-base font-thin text-center;

    a:hover {
      @apply underline;
    }
  }

  .about-us {
    @apply w-full flex flex-col items-center text-lightGrey;

    @screen md {
      @apply w-2/3;
    }

    .about-us-title {
      @apply text-3xl mb-8;
    }

    .about-us-content {
      @apply text-base w-full;

      div {
        @apply mb-3;
      }
    }
  }

  .categories {
    @apply w-full pl-0;

    @screen md {
      @apply w-1/3 pl-12;
    }

    .categories-title {
      @apply text-xl text-lightGrey uppercase pb-4 pt-8;

      @screen md {
        @apply pt-0;
      }
    }

    .categories-content {
      @apply flex flex-row flex-wrap text-base text-lightGrey;
    }

    .categories-link {
      @apply w-1/3;

      @screen md {
        @apply w-1/2;
      }

      @screen lg {
        @apply w-1/3;
      }
    }

    .category-title {
      @apply cursor-pointer;
    }

    .category-title:hover {
      @apply underline;
    }
  }

  .social {
    @apply text-2xl text-lightGrey mt-8 flex flex-row justify-center align-middle;


    .social-icon {
      @apply px-2 cursor-pointer;
    }
  }
}

.legal-disclaimer {
  @apply px-8 pb-4 max-w-screen-lg text-lightGrey text-base mx-auto italic text-left;
}