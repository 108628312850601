.document-list {
  @apply flex flex-row flex-wrap mx-2;

  .document-list-item {
    @apply w-full p-2;

    @screen md {
      @apply w-1/2;
    }

    @screen xl {
      @apply w-1/3;
    }

    @screen xxxl {
      @apply w-1/4;
    }
  }
}
