.document-header-container {
  @apply mx-4 relative;
  background: linear-gradient(grey, black);

  img {
    @apply object-cover w-full h-72 opacity-50;

    @screen md {
      @apply h-96;
    }

    @screen lg {
      @apply h-108;
    }
  }

  .document-header {
    @apply text-white font-body text-xl absolute inset-0 flex justify-center flex-col mx-4;
    margin-left: 20%;

    @screen md {
      @apply text-2xl;
    }

    @screen lg {
      @apply text-3xl;
    }
  }

  .document-header-title {
    @apply flex flex-row items-center ml-4;
  }

  .document-header-icon {
    @apply mr-2 w-12 h-12 opacity-100;
  }

  .document-header-title-text {
    @apply flex flex-col;
  }

  .document-header-title-text-company {
    @apply text-sm;

    @screen md {
      @apply text-base;
    }
  }

  .document-header-title-text-date {
    @apply text-xs;

    @screen md {
      @apply text-sm;
    }
  }
}