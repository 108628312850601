.social-share {
  @apply flex flex-row w-full justify-center absolute bottom-0 left-0 -mb-16;

  @screen lg {
    @apply flex flex-col w-auto absolute sticky justify-start top-25 float-left -ml-20;
  }

  .social-share-button {
    @apply mb-2;
  }
}
